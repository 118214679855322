
































































import './styles/WorkAddingPopup.scss';
import './styles/WorkAddingPopupAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IDockPack, IWork } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListActions } from '@store/modules/work-list/Types';
import { PopupActions } from '@store/Popup';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { IWorkListPopupsState } from '@store/modules/work-list/modules/popups/Interfaces';
import { IAddNewWorkPostData } from '@/store/modules/work-list/interfaces/ApiRequests';

const NSWorkList = namespace('storeWorkList');
const NSWorkListPopups = namespace('storeWorkList/storeWorkListPopups');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'WorkAddingPopup',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseComposite: () => import('@components/BaseComposite/BaseComposite.vue'),
        BaseSelect: () => import('@components/BaseSelect'),
    },
})
export default class WorkAddingPopup extends Vue {
    $refs!: { basePopup: any }

    @NSWorkList.Getter('workList') workList!: IWork[];
    @NSProject.Getter('projectId') projectId!: number;
    @NSDocuments.Getter('packPDList') packPDList!: IDockPack[];
    @NSWorkListPopups.Getter('workAddingPopupToggler') workAddingPopupToggler!: boolean;
    @NSWorkList.Action(WorkListActions.A_ADD_NEW_WORK)
    addNewWorkAction!: (
        payload: {
            data: IAddNewWorkPostData;
            workToAddInto: IWork[];
            workToAdd: IWork;
            isRootWorkListSelected: boolean;
        },
    ) => Promise<void>;
    @NSWorkListPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IWorkListPopupsState>,
    ) => void;

    isRootWorkListSelected = true;
    workToAddInto = {} as IWork;
    documentCategory = {} as IDockPack;
    newWorkTitle = '';
    realtimeValidation = false;

    errors = {
        title: false,
        documentCategory: false,
    }

    @Watch('workAddingPopupToggler')
    watchPopupOpen() {
        if (this.workAddingPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    selectRootWorkListToAddInto(): void {
        this.isRootWorkListSelected = true;
        this.workToAddInto = {} as IWork;
    }

    selectWorkToAddInto(work: IWork): void {
        this.isRootWorkListSelected = false;
        this.workToAddInto = work;
    }

    formWorkAddingPostData(): IAddNewWorkPostData {
        return {
            appId: this.projectId,
            name: this.newWorkTitle,
            packPDId: this.documentCategory.uuid,
            parentId: this.isRootWorkListSelected ? '' : this.workToAddInto.uuid,
        };
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    validateFields() {
        this.errors.title = !this.newWorkTitle;
        this.errors.documentCategory = !this.documentCategory?.uuid;
        this.realtimeValidation = true;
    }

    addNewWork(): void {
        const workToAdd: IWork = {
            uuid: '',
            title: '',
            constructs: [],
            children: [],
        };
        const workToAddInto: IWork[] = this.isRootWorkListSelected
            ? this.workList
            : this.workToAddInto.children;
        const data = this.formWorkAddingPostData();

        workToAdd.title = data.name;

        this.validateFields();

        if (!this.errorExists) {
            this.addNewWorkAction({
                data,
                workToAddInto,
                workToAdd,
                isRootWorkListSelected: this.isRootWorkListSelected,
            })
                .then(() => {
                    this.closePopup();
                    this.$emit('success-add-work');
                    this.resetFields();
                    this.resetErrors();
                });
        }
    }

    resetFields(): void {
        this.isRootWorkListSelected = true;
        this.workToAddInto = {} as IWork;
        this.documentCategory = {} as IDockPack;
        this.newWorkTitle = '';
        this.realtimeValidation = false;
    }

    resetErrors(): void {
        this.errors = {
            title: false,
            documentCategory: false,
        }
    }

    closePopup(): void {
        this.togglePopup({
            popupToggler: 'workAddingPopupToggler',
            state: false,
        });
    }
}
